import { HttpStatusCode } from '@solidjs/start';
import { ArticleTile, Button, Container, Heading, Link, Page, Section } from '@troon/ui';
import { createAsync } from '@solidjs/router';
import { For, Suspense } from 'solid-js';
import { Title } from '@solidjs/meta';
import { NoHydration } from 'solid-js/web';
import { IconArrowLeftMd } from '@troon/icons/arrow-left-md';
import { IconArrowRightMd } from '@troon/icons/arrow-right-md';
import { createContentfulListRequest } from '../../content/[model]/_client';
import { getConfigValue } from '../../../modules/config';
import { Hero } from '../../../components/hero/photo';
import { RichText } from '../../../components/rich-text';
import type { RouteDefinition } from '@solidjs/router';

export default function IndustryInsights() {
	const releases = createAsync(async () => getArticles(), { deferStream: true });

	return (
		<>
			<Title>Industry Insights | Management Services | Troon</Title>

			<NoHydration>
				<Hero src={`${getConfigValue('IMAGE_HOST')}/web/hero/management-services.jpg`}>
					<Heading as="h1" class="text-center ">
						Industry Insights
					</Heading>
					<p class="text-center">Insights from industry leaders at Troon.</p>
					<Button type="button" as={Link} href="/management-services#contact" class="mx-auto size-fit grow-0">
						Get in touch <IconArrowRightMd />
					</Button>
				</Hero>
			</NoHydration>
			<Container>
				<Page>
					<Section>
						<div class="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
							<Suspense>
								<For
									each={releases()?.items}
									fallback={
										<div class="flex flex-col gap-8">
											<HttpStatusCode code={404} />
											<p>No articles found.</p>
											<Button class="w-fit" as={Link} href="/management-services">
												<IconArrowLeftMd />
												Back to Management Services
											</Button>
										</div>
									}
								>
									{(article, i) => {
										const image = article.fields.heroImage?.fields;
										return (
											<ArticleTile
												hero={
													image?.file
														? {
																src: image?.file?.url,
																alt: image?.description ?? image?.title ?? '',
																preload: i() < 3,
																loading: i() < 6 ? 'eager' : 'lazy',
															}
														: undefined
												}
												url={`/management-services/industry-insights/${article.fields.slug}`}
												title={article.fields.title}
											>
												<RichText document={article.fields.summary} />
											</ArticleTile>
										);
									}}
								</For>
							</Suspense>
						</div>
					</Section>
				</Page>
			</Container>
		</>
	);
}

const getArticles = createContentfulListRequest('articles', { 'fields.articleType': 'Industry Insights' });

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;
